import { mapperModuleMedia, mapperModuleNavigationLink } from '../../../../core/data-layer/modules/mappers'
import imageSizes from '../../../../core/constants/imageSizes'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import type { MapperImageOptions, MapperOptions } from '../../../../core/types'

/**
 * Block quote mapper
 */
const mapMedia = (item: any, imageOptions: MapperImageOptions) => {
    const contentType = item?.__typename || 'ModuleMedia'

    switch (contentType) {
        case 'ModuleMedia': {
            return { image: mapperModuleMedia(item, imageOptions) }
        }

        default: {
            return {}
        }
    }
}
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    const aspectRatios = {
        '4x3': imageSizes.columns6_43,
        '1x1': imageSizes.columns6_11,
        '3x4': imageSizes.columns6_34
    }

    const mediaPosition = data.mediaPosition?.toLowerCase() || 'left'

    const fallBackImageSizes = aspectRatios[data.mediaAspectRatio] || imageSizes.columns6_43

    const fallbackImageOptions = {
        resize: true,
        lazy: true,
        sizes: fallBackImageSizes
    }

    return {
        id: data.sys?.id || '',
        contentType: data.contentType || '',
        title: data.title || '',
        description: data.blockLandingMediaAndTextDescription || null,
        ...mapMedia(data.media, options.imageOptions || fallbackImageOptions),
        mediaPosition,
        link: mapperModuleNavigationLink(data.link, options.getUrl),
        theme: mapperGlobalDataTheme(data.sectionTheme)?.theme || null
    }
}
