import { mapperModuleMedia } from '../../modules/mappers'

/**
 * Global data article author mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */
export default (data, options) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    name: data.name || '',
    role: data.role || '',
    companyName: data.companyName || '',
    image: mapperModuleMedia(data.image, options)
  }
}
