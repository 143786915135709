import { mapperModuleMedia, mapperModuleNavigationLink } from '../../../../core/data-layer/modules/mappers'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import type { MapperOptions, ModuleMedia } from '../../../../core/types'

/**
 * BlockLogoBar
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    const { imageOptions, getUrl } = options

    return {
        id: data.sys?.id || data.id,
        items: data.logosCollection?.items?.map(
            (item: any) => {
                const image = mapperModuleMedia(item, { ...imageOptions, lazy: true }) as ModuleMedia
                return { ...image.small, isFrontifyAsset: image.original.isFrontifyAsset }
            }) || [],
        footnote: data.footnote || '',
        title: data.title || '',
        description: data.logosDescription || null,
        link: mapperModuleNavigationLink(data.link, getUrl),
        theme: mapperGlobalDataTheme(data.theme)?.theme || null
    }
}
