import { mapperModuleInternalVideo, mapperModuleMedia, mapperModuleNavigationLink, mapperModuleVideo } from '../../../../core/data-layer/modules/mappers'
import imageSizes from '../../../../core/constants/imageSizes.js'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import type { MapperImageOptions, MapperOptions } from '~/types'

const mapMedia = (item: any, imageOptions: MapperImageOptions) => {
    const contentType = item?.contentType || 'ModuleMedia'

    switch (contentType) {
        case 'ModuleMedia': {
            return { image: mapperModuleMedia(item, imageOptions) }
        }
        case 'ModuleVideo': {
            return { video: mapperModuleVideo(item, imageOptions) }
        }
        case 'ModuleInternalVideo': {
            return { video: mapperModuleInternalVideo(item, imageOptions) }
        }
        default: {
            return {}
        }
    }
}
export const mapperBlockLandscapeMediaThenContent = (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    const fallBackImageSizes = imageSizes.columns12_5269

    const fallbackImageOptions = {
        resize: true,
        lazy: true,
        sizes: fallBackImageSizes
    }

    return {
        contentType: data.contentType || '',
        id: data.sys?.id || '',
        title: data.title || '',
        description: data.blockLandscapeMediaThenContentDescription || null,
        ...mapMedia(data.media, options.imageOptions || fallbackImageOptions),
        link: mapperModuleNavigationLink(data.link, options.getUrl),
        theme: mapperGlobalDataTheme(data.sectionTheme)?.theme || null
    }
}
