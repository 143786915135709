import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import { mapperModuleMedia, mapperModuleNavigationLink } from '../../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '../../../../core/types'

export default (data:any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    const imageOptions = {
        lazy: true,
        sizes: {
            small: { width: 327, height: 184, aspectRatio: '16/9' },
            medium: { width: 324, height: 432, aspectRatio: '3/4' },
            large: { width: 576, height: 432, aspectRatio: '4/3' }
        },
        resize: true
    }
    return {
        title: data.title || '',
        description: data.description || '',
        link: mapperModuleNavigationLink(data.link, options.getUrl),
        image: mapperModuleMedia(data.image, imageOptions),
        orientation: data.orientation || '',
        theme: mapperGlobalDataTheme(data.theme)?.theme || null
    }
}
