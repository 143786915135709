import { mapperGlobalDataTheme } from '../../../core/data-layer/global-data/mappers'
import { mapperBlockLogoBar } from '../../../core/data-layer/blocks/mappers'
import { mapperModuleMedia, mapperModuleNavigationLink, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '../../../core/types'
import {
    mapperBlockContentCenteredTextOnly
} from '../blocks/block-content-centered-text-only/mapper-block-content-centered-text-only'
import {
    mapperBlockLandscapeMediaThenContent
} from '../blocks/block-landscape-media-then-content/mapper-block-landscape-media-then-content'
import {
    mapperBlockLandingMediaAndText,
    mapperBlockRelatedContent,
    mapperBlockSimpleQuote,
    mapperBlockLandingLogoBar,
    mapperBlockCampaignsMedia,
    mapperBlockLandingUspList,
    mapperBlockCampaignsCallOut,
    mapperBlockCampaignsCards,
    mapperBlockCampaignsMetrics,
    mapperBlockCampaignsThankYouContent
} from '../mappers'

const relatedContentCardsImageOptions = {
    resize: true,
    lazy: true,
    sizes: {
        small: { width: 272, height: 153, aspectRatio: '16/9' }, medium: { width: 576, height: 324, aspectRatio: '16/9' }, large: { width: 576, height: 324, aspectRatio: '16/9' }
    }
}
const blocksModuleMediaImageOptions = {
    resize: true,
    lazy: true,
    sizes: { small: { width: 272, height: 153, aspectRatio: '16/9' }, medium: { width: 576, height: 324, aspectRatio: '16/9' }, large: { width: 576, height: 324, aspectRatio: '16/9' } }
}
const blocksModuleVideoImageOptions = {
    resize: true,
    lazy: true,
    sizes: { small: { width: 272, height: 153, aspectRatio: '16/9' }, medium: { width: 576, height: 324, aspectRatio: '16/9' }, large: { width: 576, height: 324, aspectRatio: '16/9' } }
}

/**
 * Page Campaigns Detail Awareness Mapper
 * Normalizes data to be used in campaigns detail awareness pages
 */
export const mapperContentKnowledgeHubCampaignsGuide = (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    if (data.lazy) {
        return data
    }

    const mapBlock = (item: any, options: MapperOptions) => {
        const mappersByContentType = {
            BlockLandingMediaAndText: (data: any) => mapperBlockLandingMediaAndText(data, options),
            BlockLandscapeMediaThenContent: (data: any) => mapperBlockLandscapeMediaThenContent(data, options),
            BlockContentCenteredTextOnly: (data: any) => mapperBlockContentCenteredTextOnly(data, options),
            BlockCampaignsMetrics: (data: any) => mapperBlockCampaignsMetrics(data),
            ModuleMedia: (data: any) => mapperModuleMedia(data, blocksModuleMediaImageOptions),
            ModuleVideo: (data: any) => mapperModuleVideo(data, blocksModuleVideoImageOptions),
            BlockLandingUspList: (data: any) => mapperBlockLandingUspList(data),
            BlockSimpleQuote: (data: any) => mapperBlockSimpleQuote(data, options.imageOptions),
            BlockCampaignsCards: (data: any) => mapperBlockCampaignsCards(data, options.getUrl),
            BlockCampaignsCallOut: (data: any) => mapperBlockCampaignsCallOut(data, options),
            BlockLogoBar: (data: any) => mapperBlockLogoBar(data, options),
            BlockLandingLogoBar: (data: any) => mapperBlockLandingLogoBar(data, options),
            BlockCampaignsMedia: (data: any) => mapperBlockCampaignsMedia(data, options)
        }
        const mapper = mappersByContentType[item.contentType] || ((data: any) => data)
        if (!mappersByContentType[item.contentType]) {
            // eslint-disable-next-line no-console
            console.warn(`Mapper missing for content type for ${JSON.stringify(item)}`)
        }
        const mappedItem = mapper(item)
        mappedItem.contentType = item.contentType
        return mappedItem
    }

    return {
        headerLink: mapperModuleNavigationLink(data.headerLink, options.getUrl),
        secondaryHeaderLink: mapperModuleNavigationLink(data.secondaryHeaderLink, options.getUrl),
        headerEyebrow: data.headerEyebrow || '',
        headerSubtitle: data.headerSubtitle || null,
        formId: data.formId,
        showFormInHeader: data.showFormInHeader,
        formAnchorLabel: data.formAnchorLabel,
        formTitle: data.formTitle,
        formDescription: data.formDescription,
        logoBar: mapperBlockLogoBar(data.logoBar, options),
        landingLogoBar: mapperBlockLandingLogoBar(data.logoBar, options),
        contentBlocks: data.contentBlocksCollection?.items?.filter(Boolean).map((item: any) => mapBlock(item, options)) || [],
        relatedContent: mapperBlockRelatedContent(data.relatedContent, { ...options, imageOptions: relatedContentCardsImageOptions }),
        thankYouContent: mapperBlockCampaignsThankYouContent(data.thankYouContent, options),
        heroTheme: mapperGlobalDataTheme(data.heroTheme)?.theme || null
    }
}
