import { mapperModuleMedia } from '../../../../core/data-layer/modules/mappers'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import type { MapperImageOptions, ModuleMedia } from '../../../../core/types'

/**
 * Block quote mapper
 */
export default (data: any, imageOptions: MapperImageOptions) => {
    if (!data) {
        return null
    }

    const fallbackImageOptions = {
        resize: true,
        lazy: true,
        sizes: {
            small: { width: 272, height: 153, aspectRatio: '16/9' },
            medium: { width: 576, height: 324, aspectRatio: '16/9' },
            large: { width: 576, height: 324, aspectRatio: '16/9' }
        }
    }

    return {
        id: data.sys?.id || '',
        quote: data.quote || '',
        author: data.author || '',
        authorDetails: data.authorDetails || '',
        logo: (mapperModuleMedia(data.logo, imageOptions || fallbackImageOptions) as ModuleMedia)?.original,
        theme: mapperGlobalDataTheme(data.theme)?.theme || null
    }
}
