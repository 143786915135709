import { mapperModuleSocialItem } from '../../modules/mappers.js'

/**
 * Block quote mapper
 * @param {Object} data - cms response data
 * @param {Object} options - options
 * @return {Object} the normalized data
 */
export default (data, options) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    items: data.itemsCollection?.items?.map(item => mapperModuleSocialItem(item, options)) || []
  }
}
