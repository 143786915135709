import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import { mapperBlockCampaignsMetrics } from '../mappers'
import { mapperBlockContent } from '../../../core/data-layer/blocks/mappers'
import type { MapperOptions } from '../../../core/types'

/**
 * Page Campaigns Detail Awareness Mapper
 * Normalizes data to be used in campaigns detail awareness pages
 */
export const mapperContentKnowledgeHubRr23 =  (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  if (data.lazy) {
    return data
  }

  return {
    hero: mapperBlockContent(data.hero, options),
    statsBlock: mapperBlockCampaignsMetrics(data.statsBlock),
    image: mapperModuleMedia(data.image, options.imageOptions),
    formId: data.formId,
    formTitle: data.formTitle,
    formDescription: data.formDescription,
    thankYouTitle: data.thankYouTitle,
    thankYouDescription: data.thankYouDescription || null
  }
}
