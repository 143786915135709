export const mapperModuleCodeSnippet = (data: any) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    label: data.label || '',
    language: data.language || '',
    sourceCode: data.sourceCode || ''
  }
}
