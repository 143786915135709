export default (data) => {
  if (!data) {
    return null
  }
  // TODO: check if `metricsItemDescription` field is needed, since it's hidden for editing in Contentful
  return {
    metric: data.title || '',
    description: data.subtitle || '',
    itemDescription: data.metricsItemDescription || ''
  }
}
