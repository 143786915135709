/**
 * Module CodeSnippet mapper
 * @param {Object} data - content data
 * @param {Object} options - options
 * @param {Object} options.labels - global data labels
 * @return {Object} - normalized data
 */
export const mapperModuleSocialItem = (data, { labels }) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    iconName: data.iconName || '',
    url: data.url || '',
    text: `${data.iconName || ''} ${labels.opensInNewWindow}`
  }
}
