import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import mapperModuleCampaignsMetricsItem
    from '../../modules/module-campaigns-metrics-item/mapper-module-campaigns-metrics-item'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'

export default (data) => {
    if (!data) {
        return null
    }

    return {
        id: data.sys?.id || '',
        title: data.title || '',
        // @TODO: Update DsStatsBlock component to support Rich Text in the subtitle
        description: documentToPlainTextString(data.description?.json) || '',
        items: data.itemsCollection ? data.itemsCollection.items.map(mapperModuleCampaignsMetricsItem) : [],
        orientation: data.landscapeOrientation ? 'horizontal' : 'vertical',
        theme: mapperGlobalDataTheme(data.theme)?.theme || null
    }
}
