import { mapperModuleNavigationLink } from '../../../../core/data-layer/modules/mappers'

/**
 * Block related products mapper
 */
export default (data: any, getUrl: Function) => {
    if (!data) {
        return null
    }

    const links = data.relatedLinksCollection?.items?.map((item: any) => mapperModuleNavigationLink(item, getUrl))

    return {
        id: data.sys?.id || '',
        title: data.title || '',
        links: links || []
    }
}
