import type { MapperOptions } from '../../core/types'
import { mapperContentKnowledgeHubRr23 } from './retail-report/mapper-content-knowledge-hub-rr23'
import { mapperContentKnowledgeHubArticle } from './article/mapper-content-knowledge-hub-article'
import { mapperKnowledgeHubArticleSummary } from './summary/mapper-knowledge-hub-article-summary'
import { mapperKnowledgeHubArticlesSummaries } from './summary/mapper-knowledge-hub-articles-summaries'
import { mapperBlockLandscapeMediaThenContent } from './blocks/block-landscape-media-then-content/mapper-block-landscape-media-then-content'
import { mapperBlockContentCenteredTextOnly } from './blocks/block-content-centered-text-only/mapper-block-content-centered-text-only'
import { mapperContentKnowledgeHubCampaignsGuide } from './campaigns/mapper-content-knowledge-hub-campaigns-guide'
import { mapperContentKnowledgeHubCaseStudy } from './case-study/case-study-content/mapper-content-knowledge-hub-case-study'

export { default as mapperBlockRelatedLinks } from './blocks/block-related-links/mapper-block-related-links'
export { default as mapperBlockSimpleRelatedArticles } from './blocks/block-related-articles/mapper-block-simple-related-article'
export { default as mapperBlockLandingUspList } from './blocks/block-landing-usp-list/mapper-block-landing-usp-list'
export { default as mapperBlockCampaignsMedia } from './blocks/block-campaigns-media/mapper-block-campaigns-media'
export { default as mapperBlockRelatedContent } from './blocks/block-related-content/mapper-block-related-content'
export { default as mapperBlockSimpleQuote } from './blocks/block-simple-quote/mapper-block-simple-quote'
export { default as mapperBlockLandingMediaAndText } from './blocks/block-landing-media-and-text/mapper-block-landing-media-and-text'
export { default as mapperBlockLandingLogoBar } from './blocks/block-landing-logo-bar/mapper-landing-block-logo-bar'
export { default as mapperBlockKnowledgeHubChapter } from './blocks/block-knowledge-hub-chapter/mapper-block-knowledge-hub-chapter'

// Campaigns
export { default as mapperBlockCampaignsCallOut } from './blocks/block-campaigns-call-out/mapper-block-campaigns-call-out'
export { default as mapperBlockCampaignsCards } from './blocks/block-campaigns-cards/mapper-block-campaigns-cards'
export { default as mapperBlockCampaignsMetrics } from './blocks/block-campaigns-metrics/mapper-block-campaigns-metrics'
export { default as mapperBlockCampaignsThankYouContent } from './blocks/block-campaigns-thank-you-content/mapper-block-campaigns-thank-you-content'

export default {
    mapperContentKnowledgeHubRr23,
    mapperContentKnowledgeHubArticle,
    mapperKnowledgeHubArticleSummary,
    mapperKnowledgeHubArticlesSummaries,
    mapperBlockLandscapeMediaThenContent,
    mapperBlockContentCenteredTextOnly,
    mapperContentKnowledgeHubCampaignsGuide,
    mapperContentKnowledgeHubCaseStudy
}

export const TypeMapper = {
    ContentKnowledgeHubCaseStudy: (data: any, options: MapperOptions) => mapperContentKnowledgeHubCaseStudy(data, options),
    ContentKnowledgeHubCampaignsGuide: (data: any, options: MapperOptions) => mapperContentKnowledgeHubCampaignsGuide(data, options),
    ContentKnowledgeHubRr23: (data: any, options: MapperOptions) => mapperContentKnowledgeHubRr23(data, options),
    ContentKnowledgeHubArticle: (data: any, options: MapperOptions) => mapperContentKnowledgeHubArticle(data, options),
    PageKnowledgeHubDetail: (data: any, options: MapperOptions) => mapperKnowledgeHubArticleSummary(data, options),
    BlockLandscapeMediaThenContent: (data: any, options: MapperOptions) => mapperBlockLandscapeMediaThenContent(data, options),
    BlockContentCenteredTextOnly: (data: any, options: MapperOptions) => mapperBlockContentCenteredTextOnly(data, options),
}
