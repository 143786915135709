import mapperBlockCampaignsCardsItem from '../block-campaigns-cards-item/mapper-block-campaigns-cards-item'
import type { MapperOptions } from '../../../../core/types'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'

export default (data: any, getUrl: Function) => {
    if (!data) {
        return null
    }
    // TODO: check if this is the right config for the image Options
    const options: MapperOptions = {
        getUrl,
        imageOptions: {
            resize: true,
            lazy: true,
            sizes: {
                small: { width: 272, height: 153, aspectRatio: '16/9' },
                medium: { width: 576, height: 324, aspectRatio: '16/9' },
                large: { width: 576, height: 324, aspectRatio: '16/9' }
            }
        }
    }
    return {
        title: data.title || '',
        items: data.itemsCollection?.items.map((item: any) => mapperBlockCampaignsCardsItem(item, options)) || [],
        theme: mapperGlobalDataTheme(data.theme)?.theme || null
    }
}
