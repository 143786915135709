import { mapperModuleNavigationLink } from '../../../../core/data-layer/modules/mappers'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'
import type { MapperOptions } from '../../../../core/types'

export const mapperBlockContentCenteredTextOnly = (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    return {
        contentType: data.contentType || '',
        id: data.sys?.id || '',
        title: data.title || '',
        description: data.blockContentCenteredTextOnlyDescription || null,
        link: mapperModuleNavigationLink(data.link, options.getUrl),
        theme: mapperGlobalDataTheme(data.sectionTheme)?.theme || null
    }
}
