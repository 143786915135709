import { mapperModuleUsp } from '../../../../core/data-layer/modules/mappers'
import { mapperGlobalDataTheme } from '../../../../core/data-layer/global-data/mappers'

/**
 * Block module list mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */
export default (data: any) => {
    if (!data) {
        return null
    }

    return {
        contentType: data.contentType || '',
        id: data.sys?.id || '',
        title: data.title || '',
        description: data.blockLandingDescription || '',
        items: data.itemsCollection?.items?.map((item: any) => {
            const contentType = item.contentType

            if (contentType === 'ModuleUsp') {
                return mapperModuleUsp(item)
            }
            return item
        }) || [],
        theme: mapperGlobalDataTheme(data.sectionTheme)?.theme || null
    }
}
