const themeEnums = Object.freeze({
  LIGHT: 'light',
  GREY: 'grey'
})

export default (data: any) => {
  if (!data) {
    return null
  }
  return {
    id: data?.sys?.id || '',
    name: data?.internalName || '',
    theme: themeEnums[data?.theme] || ''
  }
}
