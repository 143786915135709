import axios from 'axios'
import { print } from 'graphql'
import buildQuery from '../data-layer/pages/page-knowledge-hub-articles/query-page-knowledge-hub-articles'
import { mapperKnowledgeHubArticlesSummaries } from '../data-layer/summary/mapper-knowledge-hub-articles-summaries'
import queryPageKnowledgeHubLatestArticles from '../data-layer/pages/page-knowledge-hub/query-page-knowledge-hub-latest-articles'

const LIMIT = 3

/**
 * Returns a graphql query based on the array of filters received
 * If filters is null or empty returns null
 * @param {Object[]} filters    filters (could be article types, industries, products or topics)
 */
export const buildFiltersQuery = (filters) => {
    if (!filters || filters.length === 0) {
        return null
    }

    const categories = {
        articleTypes: [],
        products: [],
        industries: [],
        topics: []
    }

    filters.forEach((filter) => {
        switch (filter.contentType) {
            case 'GlobalDataProduct':
                categories.products.push(filter.sys.id)
                break
            case 'GlobalDataIndustry':
                categories.industries.push(filter.sys.id)
                break
            case 'GlobalDataTopic':
                categories.topics.push(filter.sys.id)
                break
            case 'GlobalDataArticleType':
                categories.articleTypes.push(filter.sys.id)
                break
        }
    })

    const query = buildQuery(categories.articleTypes, categories.products, categories.industries, categories.topics)
    return query
}

/**
 * Attepts to fetch extra articles based on the received query and concats the results to the existing array
 * @param {Arary} currentArticles
 * @param {GQLQuery} query
 * @param {Object} mapperOptions
 * @returns a new array with the currentArticles + the new ones fetched using the query if applicable
 */
const fetchAndConcatNonRepeatedArticles = async (currentArticles = [], query, mapperOptions) => {
    const { locale: localeRef } = useI18n()
    const route = useRoute()
    const { queryContentful } = useQueries()
    const { getLocaleIso } = useLocales()
    const { getSlug } = usePageUtils()
    const slug = getSlug(route.path);
    const locale = getLocaleIso(localeRef.value)


    const variables = { slug, locale, skip: 0, limit: LIMIT, preview: false}

    const { articles } = await queryContentful({
        query,
        variables
    }, { log: false })

    // Also we dont get a return here because we are not getting articles BACK!!!

    const articleItems = articles.items
    const articleTotal = articles.total

    if (articleTotal > 0) {
        const idsToAvoidRepeating = currentArticles.map(item => item.id)
        const filteredArticles = articleItems.filter(article => !idsToAvoidRepeating.includes(article.sys.id))
        const mappedExtraArticles = mapperKnowledgeHubArticlesSummaries(filteredArticles, mapperOptions)
        return [...currentArticles, ...mappedExtraArticles]
    } else {
        return currentArticles
    }
}

/**
 * Performs a query based on the filtersQuery built with buildFiltersQuery if necessary
 * If items in relatedContent are enough, returns them
 * If not, attempts to fetch articles using the filters
 * If no filters or not enough results, then it queries the latest articles
 * @param {RelatedContent} relatedContent  relatedContentBlock: { filtersQuery: gql, items: KnowledgeHubArticleSummary[] }
 * @param {String[]} locale                locale
 * @param {MapperOptions} mapperOptions    mapperOptions (locale, imageOptions, getInternalURL)
 */
export const queryRelatedContent = async (relatedContent, mapperOptions) => {
    if (relatedContent?.items && relatedContent?.items?.length >= LIMIT) {
        return relatedContent?.items
    }

    let result = [...(relatedContent?.items || [])]

    if (relatedContent?.filtersQuery) {
        result = await fetchAndConcatNonRepeatedArticles(result, relatedContent?.filtersQuery, mapperOptions)
    }

    if (result.length < LIMIT) {
        result = await fetchAndConcatNonRepeatedArticles(result, queryPageKnowledgeHubLatestArticles, mapperOptions)
    }

    return result.slice(0, LIMIT)
}
