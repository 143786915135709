import { gql } from 'graphql-tag'
import fragmentPageKnowledgeHubDetail from '../page-knowledge-hub-detail/fragment-page-knowledge-hub-detail'

export default gql`
    ${fragmentPageKnowledgeHubDetail}
    query ($locale: String!, $limit: Int!, $preview: Boolean! ) {
        articles: pageKnowledgeHubDetailCollection (
            order: date_DESC,
            limit: $limit,
            locale: $locale,
            preview: $preview,
            where: {
                slug_exists: true,
                visibleInKnowledgeHub_not: false
            }
        ) {
            total
            items {
                ...PageKnowledgeHubDetailFragment
            }
        }
    }
`
