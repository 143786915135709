import {mapperKnowledgeHubArticleSummary} from '../../summary/mapper-knowledge-hub-article-summary'
import type { MapperOptions } from '../../../../core/types'

/**
 * Block Related articles mapper
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    return {
        id: data.sys?.id || '',
        title: data.title || '',
        items: data.itemsCollection?.items?.filter(Boolean).map((item: any) => mapperKnowledgeHubArticleSummary(item, options)) || []
    }
}
