import { mapperModuleMetric } from '../../modules/mappers'

/**
 * Block Metrics mapper
 */
export const mapperBlockMetrics = (data: any) => {
  if (!data) {
    return null
  }

  const getNumber = (str:string) => {
    const trimmedString = str.trim()
    const withPercentage = trimmedString.endsWith('%')
    const number = Number(trimmedString.substring(0, trimmedString.length - 1))

    return withPercentage ? number >= 0 && number <= 100 : false
  }

  let validGraphStyle:string = ''
  if (data.graphStyle && data.graphStyle !== 'NO GRAPH') {
    if (data.itemsCollection?.items?.length > 0) {
      const isValid = data.itemsCollection.items.every((item:any) => getNumber(item.metric))
      if (isValid) { validGraphStyle = data.graphStyle }
    }
  }

  return {
    id: data.sys?.id || '',
    contentType: data.contentType || '',
    title: data.title || '',
    twoColsOnDesktop: data.twoColsOnDesktop || false,
    graphStyle: validGraphStyle,
    items: data.itemsCollection?.items?.map((item: any) => mapperModuleMetric(item)) || []
  }
}
