import { format } from 'date-fns'
import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
const { getI18nFormatterLocale } = useLocales()
import imageSizes from '../../../core/constants/imageSizes'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

export const mapperKnowledgeHubArticleSummary = (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    if (!options.imageOptions) {
        options.imageOptions = {
            resize: true,
            lazy: true,
            sizes: imageSizes.related_articles
        }
    }

    const formatOptions = (options.locale && options.locale) !== 'en' ? 'd MMMM, yyyy' : 'MMMM d, yyyy'
    const dateFnsLocale = getI18nFormatterLocale(options.locale || 'en')
    const dateFormatted = data.date && format(new Date(data.date), formatOptions, { locale: dateFnsLocale })
    const image = mapperModuleMedia(data.image, options.imageOptions) as ModuleMedia

    // TODO: https://youtrack.is.adyen.com/issue/WEB-3642 Should we combine image and imageWithSizes and use only one?
    return {
        id: data.sys?.id,
        image: image?.medium || { src: '', alt: '' },
        imageWithSizes: image,
        title: data.title || '',
        tag: data.articleType?.name,
        link: {
            title: data.title,
            nuxt: true,
            openInNewWindow: false,
            url: options.getInternalUrl(data.sys?.id)
        },
        dateFormatted,
        minutesToRead: data.minutesToRead,
        shortDescription: data.shortDescription || ''
    }
}
